const flag = "production";

const env = {
  production: {
    API_URL: "https://staging3032825backend.signaps.com/api",
    DOMAIN_URL: "https://staging3032825frontend.signaps.com",
    FRONTEND_URL: "",
  },
  staging: {
    API_URL: "http://customer1.localhost:8000/api/",
    FRONTEND_URL: " ",
  },
};

export default {
  ...env[flag],
  flag: flag,
};
