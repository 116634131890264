import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  Card,
  notification,
  Skeleton,
  Badge,
  DatePicker,
  InputNumber,
} from 'antd';
import { UserOutlined, PlusOutlined } from '@ant-design/icons';
import systemService from '../../services/system';
import Header from '../../components/header';
import DefaultLayout from './../../components/layout/DefaultLayout';
import './index.css';
const { Option } = Select;

const Settings = () => {
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedExtensions, setSelectedExtensions] = useState([]);
  const { currentUser } = useSelector((state) => state.user);
  const staticAllowedExtensionsOptions = [
    'jpg',
    'gif',
    'jpeg',
    'png',
    'doc',
    'docx',
    'xls',
    'txt',
    'xlsx',
    'ppt',
    'pptx',
    'csv',
    'pdf',
    'pst',
    'ost',
    'eml',
    'oft',
    'dat',
    'vcf',
    'msg',
    'svg',

    
  ];
  const [data, setData] = useState(null);

  const [fields, setFields] = useState([]);
  const [storageFields, setStorageFields] = useState([]);
  const [userFields, setUserFields] = useState([]);


  const validateEmailFormat = (rule, value, callback) => {
    if (!value) {
      callback('Please enter email username!');
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      callback('Please enter a valid email username!');
    } else {
      callback();
    }
  };

  const getSystemSettings = async () => {
    setLoading(true);
    try {
      const response = await systemService.getSystemSettings();
      const systemSettings = response[0];
  
      let allowedExtensionsArray = [];
      if (systemSettings.allowed_extensions) {
        try {
          allowedExtensionsArray = JSON.parse(systemSettings.allowed_extensions);
        } catch (jsonError) {
          console.log(jsonError);
          allowedExtensionsArray = [];
        }
      }
  
      setData(systemSettings);
      setLoading(false);
  
      form.setFieldsValue({
        email_transport: systemSettings.email_transport,
        email_host: systemSettings.email_host,
        email_username: systemSettings.email_username,
        email_password: systemSettings.email_password,
        email_port: systemSettings.email_port,
        email_encryption: systemSettings.email_encryption,
        max_upload_size: systemSettings.max_upload_size,
        allowed_extensions: allowedExtensionsArray,
      });
      
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Error',
        description: error.response,
      });
    }
  };

  useEffect(() => {
    getSystemSettings();
  }, []);

  const onFinish = async (values) => {
    //console.log(values)
    setBtnLoading(true);
    try {
      const response = await systemService.updateSystemSettings(values);
      if (response.success) {
        notification.success({
          message: 'Settings has been Updated',
          description: response.message,
        });
      }
    } catch (error) {
      console.log(error);
      setBtnLoading(false);
      notification.error({
        message: 'Error',
        description: error.response.data.message,
      });
    } finally {
      setBtnLoading(false);
    }
  };


    const addField = (fieldBox) => {
      console.log('yes')
      console.log(fieldBox)
      if(fieldBox === "transaction_limits"){
        const newField = {
          id: fields.length,
          date_range: '',
          transactions: '',
        };
        setFields([...fields, newField]);
      }
      if(fieldBox === "storage_limits"){
        const newField2 = {
          id: storageFields.length,
          date_range: '',
          mbs: '',
        };
        setStorageFields([...storageFields, newField2]);
      }
      if(fieldBox === "user_limits"){
        const newField3 = {
          id: userFields.length,
          date_range: '',
          users: '',
          users_login: '',
        };
        setUserFields([...userFields, newField3]);
      }
    
  };

  const removeField = (id, fieldBox) => {

    if(fieldBox === "transaction_limits"){
      const updatedFields = fields.filter((field) => field.id !== id);
      setFields(updatedFields);
    }
    if(fieldBox === "storage_limits"){
      const updatedFields2 = storageFields.filter((field) => field.id !== id);
      setStorageFields(updatedFields2);
    }
    if(fieldBox === "user_limits"){
      const updatedFields3 = userFields.filter((field) => field.id !== id);
      setUserFields(updatedFields3);
    }
  };

  return (
    <DefaultLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Header
            icon={<UserOutlined />}
            title="Advance Settings"
            right={<></>}
          />
        </Col>
      </Row>
      {/*<Card>*/}
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          scrollToFirstError
          className="mt-20"
        >

        <Card title="Workflow transaction limits " style={{marginRight: '30px', marginLeft: '30px', marginBottom: '30px'}}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              {fields.map((field, index) => (
              
                  <Row
                    gutter={[12, 12]}
                    key={field.id}
                    style={{ display: 'flex', marginBottom: 34 }}
                  >
                    <Col span={8}>
                      <Form.Item
                        name={['transaction_limits', field.id, 'date_range']}
                        label="Date Range"
                        rules={[
                          {
                            required: true,
                            message: 'Please select a date range',
                          },
                        ]}
                      >
                        <DatePicker.RangePicker size="large" style={{ width: '80%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name={['transaction_limits', field.id, 'transactions']}
                        label="Number of Transactions"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter the number of transactions',
                          },
                        ]}
                      >
                        <InputNumber size="large" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>

                    <Col span={2}>
                      {field.id === 0 ? null : (
                        <Button
                          style={{ marginTop: '27px' }}
                          size="large"
                          onClick={() => removeField(field.id,'transaction_limits')}
                        >
                          -
                        </Button>
                      )}
                    </Col>
                  </Row>
              ))}
              <Form.Item
                className=""
                style={{ marginRight: '10px' }}
              >
                <Button
                  type="dashed"
                  onClick={() => addField('transaction_limits')}
                  icon={<PlusOutlined />}
                >
                  Add More
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
         <Card title="Storage limits " style={{marginRight: '30px', marginLeft: '30px', marginBottom: '30px'}}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              {storageFields.map((field, index) => (
              
                  <Row
                    gutter={[12, 12]}
                    key={field.id}
                    style={{ display: 'flex', marginBottom: 34 }}
                  >
                    <Col span={8}>
                      <Form.Item
                        name={['storage_limits', field.id, 'date_range']}
                        label="Date Range"
                        rules={[
                          {
                            required: true,
                            message: 'Please select a date range',
                          },
                        ]}
                      >
                        <DatePicker.RangePicker size="large" style={{ width: '80%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name={['storage_limits', field.id, 'mbs']}
                        label="Data (In Mbs)"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter the number of mbs',
                          },
                        ]}
                      >
                        <InputNumber size="large" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>

                    <Col span={2}>
                      {field.id === 0 ? null : (
                        <Button
                          style={{ marginTop: '27px' }}
                          size="large"
                          onClick={() => removeField(field.id,'storage_limits')}
                        >
                          -
                        </Button>
                      )}
                    </Col>
                  </Row>
              ))}
              <Form.Item
                className=""
                style={{ marginRight: '10px' }}
              >
                <Button
                  type="dashed"
                  onClick={() => addField('storage_limits')}
                  icon={<PlusOutlined />}
                >
                  Add More
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card> 

         <Card title="Users Restriction " style={{marginRight: '30px', marginLeft: '30px', marginBottom: '30px'}}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              {userFields.map((field, index) => (
              
                  <Row
                    gutter={[12, 12]}
                    key={field.id}
                    style={{ display: 'flex', marginBottom: 34 }}
                  >
                    <Col span={8}>
                      <Form.Item
                        name={['user_limits', field.id, 'date_range']}
                        label="Date Range"
                        rules={[
                          {
                            required: true,
                            message: 'Please select a date range',
                          },
                        ]}
                      >
                        <DatePicker.RangePicker size="large" style={{ width: '80%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name={['user_limits', field.id, 'total_users']}
                        label="No of Total Users"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter the number of mbs',
                          },
                        ]}
                      >
                        <InputNumber size="large" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        name={['user_limits', field.id, 'total_users_loggin']}
                        label="No of Login Users at a time"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter the number of mbs',
                          },
                        ]}
                      >
                        <InputNumber size="large" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>

                    <Col span={2}>
                      {field.id === 0 ? null : (
                        <Button
                          style={{ marginTop: '27px' }}
                          size="large"
                          onClick={() => removeField(field.id,'user_limits')}
                        >
                          -
                        </Button>
                      )}
                    </Col>
                  </Row>
              ))}
              <Form.Item
                className=""
                style={{ marginRight: '10px' }}
              >
                <Button
                  type="dashed"
                  onClick={() => addField('user_limits')}
                  icon={<PlusOutlined />}
                >
                  Add More
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card> 



<Row>
  <Col lg={24} md={24} sm={24} xs={24}>
        <div className="text-right" style={{ marginRight: '30px' }}>
          <Form.Item>
            <Button
              loading={btnLoading}
              size="large"
              type="primary"
              htmlType="submit"
            >
              Save Settings
            </Button>
          </Form.Item>
        </div>
      </Col>
  </Row>

        </Form>
      {/*</Card>*/}
    </DefaultLayout>
  );
};

export default Settings;
